<template>
    <div class="gallery">
        <image-grid :images="images" :captions="captions" />
    </div>
</template>

<script>
import ImageGrid from './components/ImageGrid';
export default {
    name: 'Gallery',
    components: { ImageGrid },
    data() {
        return {
            images: [
                {
                    id: 0,
                    url: 'https://static.heartwoodcarpentry.org/gallery/10.jpg',
                    caption:
                        'Rossmore Blvd. - This porch had some water damage and extensive rot. The owners wanted a total reconstruction and reproduction of the existing porch and details. So much so, that Joe had to use the original eavestrough and downspouts. New capitals were fabricated and an assortment of mouldings were used from Brenlo Mouldings. Some profiles were taken out of a bigger mouldings and a few were a combo of smaller mouldings to create bigger ones.',
                },
                {
                    id: 1,
                    url: 'https://static.heartwoodcarpentry.org/gallery/16.jpg',
                    caption:
                        'Markland Street Media Room - New design in an old house. Second floor media room working alongside the owner George who did a lot of the work behind the scenes.',
                },
                {
                    id: 2,
                    url: 'https://static.heartwoodcarpentry.org/gallery/0.jpg',
                    caption:
                        'Durand Georgian Detail - Mitre and butt joints in soffit were hidden under the dentils, creating a maintenance free finish',
                },
                {
                    id: 3,
                    url: 'https://static.heartwoodcarpentry.org/gallery/3.jpg',
                    caption:
                        'Durand Georgian Cornice - Detail of the ten pieces of moulding that went into the final reproduction.',
                },
                {
                    id: 4,
                    url: 'https://static.heartwoodcarpentry.org/gallery/7.jpg',
                    caption:
                        'Durand Media Room - The owners picked out the flooring, which was a good choice. Joe installed it with a riser to accommodate theatre seating.',
                },
                {
                    id: 5,
                    url: 'https://static.heartwoodcarpentry.org/gallery/4.jpg',
                    caption:
                        "West End Victorian - This was Joe's original sketch to scale and the transition to finished product.",
                },
                {
                    id: 6,
                    url: 'https://static.heartwoodcarpentry.org/gallery/9.jpg',
                    caption:
                        'West End Victorian - This was a great project; To design and build a new porch for a 1895 Victorian house. Joe took the stop chamfers in the brick and the circular window in the gable and repeated those themes extensively throughout the design. The porch was painted five different historical colours, thanks to the expertise and guidance of Kim at Bay City Paints.',
                },
                {
                    id: 7,
                    url: 'https://static.heartwoodcarpentry.org/gallery/12.jpg',
                    caption:
                        'Lakeshore Gates - The original gates failed the owners over the years as water/snow caused the damage. The owners wanted to keep the existing design and duplicate it. Joe suggested using certainteed (plastic) in the horizontal places where rain and snow were going to accumulate and slope them to accommodate runoff and extend the life of the gates.',
                },
                {
                    id: 8,
                    url: 'https://static.heartwoodcarpentry.org/gallery/11.jpg',
                    caption:
                        'Dundas Deck in Ipe - The owners had a cedar deck that was rotting, Joe had used this South American hardwood before and suggested a longer lasting material that was still natural. Joe designed the deck to take advantage of the long lengths and avoided joints and seams. The sweeping arch and the split level provided a pleasurable space to entertain.',
                },
                {
                    id: 9,
                    url: 'https://static.heartwoodcarpentry.org/gallery/5.jpg',
                    caption: 'Need caption - dad standing with beer',
                },
                {
                    id: 10,
                    url: 'https://static.heartwoodcarpentry.org/gallery/6.jpg',
                    caption:
                        'Arts and Crafts Kitchen in Oakville (2001) - After much research he realized that, simply put, this movement stood for traditional craftsmanship and simple rectangular forms using local materials of the time. The movement was a response tot he over elaborate Victorian Architecture. This design was inspired by the simplicity of the movement.',
                },
                {
                    id: 11,
                    url: 'https://static.heartwoodcarpentry.org/gallery/8.jpg',
                    caption:
                        'Lakeshore Landscaping Project - The was a perfect marriage between framing and finishing.',
                },
                {
                    id: 12,
                    url: 'https://static.heartwoodcarpentry.org/gallery/13.jpg',
                    caption:
                        'You may not appreciate the framing part of the job and the installation of the windows, until you have to put casing around windows such as this. If the windows were not put in at the same level nor if they were not plumb with the one above and if they were not set the same distance away from the drywall for jamb extensions, this would have been hell to trim.',
                },
                {
                    id: 13,
                    url: 'https://static.heartwoodcarpentry.org/gallery/14.jpg',
                    caption:
                        'Panels in Olde Oakville - This job enatiled panels on the second floor coming down the stairs and flowing through the front entrance foyer. It was a challenge to design the panels to the right scale and proportion in spite of the four doorways, multiple arches and the stairwell.',
                },
                {
                    id: 14,
                    url: 'https://static.heartwoodcarpentry.org/gallery/15.jpg',
                    caption: 'Roseland Porch - As was as the porch, joe applied the shiplap siding and the trim.',
                },
                {
                    id: 16,
                    url: 'https://static.heartwoodcarpentry.org/gallery/2.jpg',
                    caption:
                        'Guelph Mansion - This entailed working on the exterior brackets and porches and the third floor trim where the casing involved thirty pieces of moulding and overlays and four piece baseboards.',
                },
                {
                    id: 17,
                    url: 'https://static.heartwoodcarpentry.org/gallery/17.jpg',
                    caption:
                        'Brandford Porch Pillars - The owner was inspired by some pillars in Vancouver and wanted to replicate this for his porch. From a photograph, Joe reproduced them to scale. Note the tapered corners of the pillars starting at an inch and a half at the top down to nothing at the bottom.',
                },
                // 'https://static.heartwoodcarpentry.org/gallery/0.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/1.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/2.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/10.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/16.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/0.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/3.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/7.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/4.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/9.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/12.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/11.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/5.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/6.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/8.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/13.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/14.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/15.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/2.jpg',
                // 'https://static.heartwoodcarpentry.org/gallery/17.jpg',
            ],
        };
    },
};
</script>

<style scoped>
.gallery {
    padding-top: 5%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}
</style>
